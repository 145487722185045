<template>
	<div>
		<v-row no-gutters class="py-6">
			<v-col cols="6" class="d-flex align-center">
				<img
					v-if="value.category_id === 1"
					:src="$store.state.icons.icons['Lumber']"
					style="height:36px;"
					alt
				/>
				<img
					v-if="value.category_id === 2"
					:src="$store.state.icons.icons['Electric']"
					style="height:36px;"
					alt
				/>
				<img
					v-if="value.category_id === 3"
					:src="$store.state.icons.icons['Plumbing']"
					style="height:36px;"
					alt
				/>
				<img
					v-if="value.category_id === 4"
					:src="$store.state.icons.icons['Masonry']"
					style="height:36px;"
					alt
				/>
				<img
					v-if="value.category_id === 5"
					:src="$store.state.icons.icons['ToolBoxes']"
					style="height:36px;"
					alt
				/>

				<div class="pl-7">
					<p class="heading-mf15">{{ value.list_name }}</p>
					<p class="heading-rf13">List ref: {{ value.list_id }}</p>
				</div>
			</v-col>
			<v-col cols="6" class="d-flex align-center justify-end">
				<app-bid-hours v-model="value.created_at" v-if="value.status === 'AC'"></app-bid-hours>
				<app-list-hours v-model="value.idle_time" v-else></app-list-hours>
				<div
					v-if="value.status === 'L'"
					class="text-tag-btn text-tag-blue d-flex align-center justify-center mr-2"
				>Awaiting your bid</div>
				<div
					v-else-if="value.status === 'E'"
					class="text-tag-btn text-tag-red d-flex align-center justify-center mr-2"
				>Bid Expired</div>
				<div
					v-else-if="value.status === 'R'"
					class="text-tag-btn text-tag-red d-flex align-center justify-center mr-2"
				>Rejected</div>
				<div
					v-else-if="value.status === 'AC'"
					class="text-tag-btn text-tag-blue d-flex align-center justify-center mr-2"
				>Submited</div>
			</v-col>
		</v-row>

		<div>
			<v-row no-gutters class="map-box">
				<v-img max-height="80" alt="Map Image" style="border-radius:4px;" lazy-src :src="getMapSrc()"></v-img>
			</v-row>
			<div class="d-flex align-center justify-center pb-5" style="margin-top:-60px;">
				<div class="map-btn" v-on:click="openMap()">
					<img :src="$store.state.icons.icons['DeliveryVan']" alt />
					Check on map
				</div>
			</div>
		</div>

		<App-map-view v-model="value" ref="openmap"></App-map-view>
	</div>
</template>

<script>
const MapView = () => import("./MapView");
import { Map_Key } from "../../config/variable";
export default {
	components: {
		AppMapView: MapView
	},

	props: {
		value: Object
	},

	methods: {
		getMapSrc() {
			return this.value.url != undefined
				? this.value.url["list_header"]
				: "https://the-build-static-content.s3.us-east-2.amazonaws.com/icons/map-image.png";
		},

		openMap() {
			this.$refs.openmap.openMap();
		}
	}
};
</script>